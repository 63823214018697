export const ST_UPDATE = 'ST_UPDATE';
export const SET_CONTRIBUTEE = 'SET_CONTRIBUTEE';
export const SET_CONTRIBUTOR = 'SET_CONTRIBUTOR';
export const SET_PARTY = 'SET_PARTY';
export const SET_AMOUNT_RANGE = 'SET_AMOUNT_RANGE';
export const SET_DATE_RANGE = 'SET_DATE_RANGE';
export const SET_GEOGRAPHY = 'SET_GEOGRAPHY';
export const SET_AGE_RANGE = 'SET_AGE_RANGE';
export const SET_HISPANIC = 'SET_HISPANIC';
export const SET_GENDER = 'SET_GENDER';
export const SET_FEC = 'SET_FEC';

const initalState = {
	st:'tx',
	contributee:[],
	contributor:'',
	party:[-3,3],
	amount:{min:'',max:''},
	contrib_date:{min:null,max:null},
	geography:[],
	age:{min:'',max:''},
	hispanic:false,
	gender:{male:false,female:false,unknown:false},
	fec:false,
	collection:[],
	data_max:'',
	data_min:''
}

export default function target(state = initalState, action){
	switch(action.type){
		case ST_UPDATE:
			return {
				...state,
				st: action.st
			};
		case SET_CONTRIBUTEE:
			return {
				...state,
				contributee: action.contributee
			};
		case SET_CONTRIBUTOR:
			return {
				...state,
				contributor: action.contributor
			};
		case SET_PARTY:
			return {
				...state,
				party: action.party
			};
		case SET_AMOUNT_RANGE:
			return {
				...state,
				amount: action.amount
			};
		case SET_DATE_RANGE:
			return {
				...state,
				contrib_date: action.contrib_date
			};
		case SET_GEOGRAPHY:
			return {
				...state,
				geography: action.geography
			};
		case SET_AGE_RANGE:
			return {
				...state,
				age: action.age
			};
		case SET_HISPANIC:
			return {
				...state,
				hispanic: action.hispanic
			};
		case SET_GENDER:
			return {
				...state,
				gender: action.gender
			};
		case SET_FEC:
			return {
				...state,
				fec: action.fec
			};
		default:
			return state
	}
}