import React from "react";
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { SET_CONTRIBUTEE } from '../reducers/target';

const mapStateToProps = state => ({
  contributee: state.target.contributee,
  st: state.target.st
});

const mapDispatchToProps = dispatch => ({
  onContributeeSelect: (selection) =>
    dispatch({ type: SET_CONTRIBUTEE, contributee:selection}),
});

export class Contributee extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			values: [],
			inputValue:'',
		}
		this.handleChange = this.handleChange.bind(this);
		this.handleInput = this.handleInput.bind(this);
	}

	handleChange(event, newValue){
		this.props.onContributeeSelect(newValue)
	}

	handleInput(event, newInput){
		this.setState((state) => ({
			inputValue: newInput,
		}));

	
		fetch('/recipients?q='+newInput+'&st='+this.props.st)
		.then( res => res.json())
		.then( data => {
			if(!data.errors){
				let valuesArr = data.map(item => item.recipient)
				this.setState({values:valuesArr})
			}
		});

	}

	render(){
		return (
			<div className="filter">
				<Autocomplete
					multiple
					freeSolo
					includeInputInList={true}
					value={this.props.contributee}
					inputValue={this.state.inputValue}
					options={this.state.values}
					getOptionLabel={(option)=> option }
					renderInput={
						(params) => <TextField {...params} 
						label="Contributee"
						variant="outlined"
						size="small"/>
					}
					onChange={this.handleChange}
					onInputChange={this.handleInput}
				/>
			</div>
		);
	}

}

export default connect(mapStateToProps, mapDispatchToProps)(Contributee);
