import React from "react";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { StaticMap } from 'react-map-gl';

const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1Ijoic2dzIiwiYSI6IkhWVkxqRGcifQ.EEFI5awzJOeJIesc59epaQ';
const MAPBOX_STYLE = 'mapbox://styles/sgs/cjt63rq3z05nt1fmgj19wrx77';

export default class Login extends React.Component {
	constructor(props){
		super(props);
		this.state={
			email:'',
			password:''
		}
		this.onSubmit = this.onSubmit.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}

	handleInputChange(event){
		const { value, name } = event.target;
		this.setState({
			[name]: value
		});
	}
	componentDidMount(){
		fetch('/checkToken')
		.then(response => {
			if(response.status === 200){
				this.props.history.push('/');
			}
		});
	}

	onSubmit(event){
	    event.preventDefault();
	    fetch('/auth',{
	    	method:'POST',
	    	headers:{'Content-Type':'application/json'},
	    	body: JSON.stringify(this.state),
	    }).then( response =>{
	    	if(response.status === 200){
	    		console.log(response);
	    		this.props.history.push('/');
	    	} else {
	    		const error = new Error(response.error);
	    		throw error;
	    	}
	    }).catch(error => {
	    	alert('Error loggin in please try again');
	    });
	}

	render(){
		return (
			<div>
				<div id="login">
					<div className="brand">
						<img src="/logo.png" alt='' />
					</div>
					<h2>Enter your email address and password to begin</h2>
					<form className='wrapper' onSubmit={this.onSubmit}>
						<TextField
							label="Email"
							variant="outlined"
							type="email"
							required
				            fullWidth
				            name="email"
				            onChange={this.handleInputChange}
						/>
						<TextField 
							label="Password"
							variant="outlined"
							type="password"
							required
							fullWidth
							name="password"
							onChange={this.handleInputChange}
						/>
						<Button 
							type="submit"
							fullWidth
							size="large"
							variant="contained"
							color="primary"
		            	>Log in</Button>
	            	</form>
	            	<a className="resetlink" href="/reset/password">Click here to reset your password</a>
				</div>
				<StaticMap reuseMaps width={'100vw'} height={'100vh'} latitude={31.409912} longitude={-99.09668} zoom={6} mapStyle={MAPBOX_STYLE} mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}/>
			</div>
		);
	}
}