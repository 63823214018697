import React from "react";
import { connect } from 'react-redux';
import Slider from '@material-ui/core/Slider';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { SET_PARTY } from '../reducers/target';

const mapStateToProps = (state) => ({
  party:state.target.party,
  st:state.target.st
});

const mapDispatchToProps = dispatch => ({
  onPartySet: (value) =>
    dispatch({ type: SET_PARTY, party:value}),
});

export class PartySlider extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			rep:false,
			dem:false,
			oth:false
		}
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event, newValue){
		if(typeof newValue === 'boolean'){
			this.setState({[event.target.name]:newValue}, function(){
				let partyset = Object.keys(this.state).filter(k => this.state[k])
				this.props.onPartySet(partyset)
			})
		} else {
			this.props.onPartySet(newValue)
		}
	}

	render(){
		if( this.props.st === 'tx'){
			return (
				<div className="filter">
					<span>Party of Contributor</span>
					<Slider 
					value={this.props.party}
					defaultValue={[-3,3]}
					min={-3}
					max={3}
					marks={[
						{value:-3,label:"D+3"},
						{value:-2,label:"D+2"},
						{value:-1,label:"D+1"},
						{value:0,label:"0"},
						{value:1,label:"R+1"},
						{value:2,label:"R+2"},
						{value:3,label:"R+3"},
					]}
					onChange={this.handleChange}
					/>
				</div>
			);
		}
		else{
			return(
			<div className="filter">
				<FormControl component="fieldset">
					<FormLabel>Party</FormLabel>
					<FormGroup row>
						<FormControlLabel control={<Checkbox name="rep" checked={this.state.rep} onChange={this.handleChange} />} label="REP" />
						<FormControlLabel control={<Checkbox name="dem" checked={this.state.dem} onChange={this.handleChange} />} label="DEM" />
						<FormControlLabel control={<Checkbox name="oth" checked={this.state.oth} onChange={this.handleChange} />} label="OTHER" />
					</FormGroup>
				</FormControl>
			</div>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PartySlider);
