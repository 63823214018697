import React from "react";
import { connect } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { SET_FEC } from '../reducers/target';

const mapStateToProps = state => ({
	fec: state.target.fec
});

const mapDispatchToProps = dispatch =>({
	onFECSet: (value) => dispatch({
		type: SET_FEC,
		fec: value
	}),
});

export class FEC extends React.Component {
	constructor(props){
		super(props);
		this.handleChange = this.handleChange.bind(this);
	}
	static defaultProps ={
		fec: false
	}

	handleChange(event){
		this.props.onFECSet(event.target.checked)
	}

	render(){
		return (
			<div className="filter">
				<FormControlLabel 
					control={
						<Switch name="fec" 
							checked={this.props.fec} 
							onChange={this.handleChange} />
					}
					label="Include FEC" />
			</div>
		);
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(FEC);