import React from "react";
import { connect } from 'react-redux';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { ST_UPDATE, SET_PARTY, SET_GENDER } from '../reducers/target';
import { SET_VIEW } from '../reducers/map';

let stateViews = {
	tx:{
		longitude:-99.09668,
		latitude:31.409912,
		zoom:6
	},
	ar:{
		longitude:-92.391895,
		latitude:34.848645, 
		zoom:7
	},
	ok:{
		longitude:-97.869,
		latitude:35.431,
		zoom:7
	}
};

const mapStateToProps = state => ({
  st: state.target.st
});

const mapDispatchToProps = dispatch => ({
  OnSTUpdate: function(value){
  	dispatch({ type: ST_UPDATE, st:value});
  	dispatch({ type: SET_VIEW, view:stateViews[value]});
  	if(value === 'tx'){
  		dispatch({ type: SET_PARTY, party:[-3,3]});
  	}else{
  		if( value === 'ok' || value === 'ar'){
		  	dispatch({ type: SET_GENDER, gender:{female: false, male: false, unknown: false}});
			}
	  	dispatch({ type: SET_PARTY, party:[]});
		}
  }
});

export class StateSelection extends React.Component {
	constructor(props){
		super(props);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event){
		console.log(event.target.value);
		this.props.OnSTUpdate(event.target.value);
	}

	render(){
		return (
			<div className="state-filter">
				Select State:
				<Select
					variant="standard"
					size="small"
					onChange={this.handleChange}
					value={this.props.st}
				>
					<MenuItem value='tx'>TX</MenuItem>
					<MenuItem value='ar'>AR</MenuItem>
					<MenuItem value='ok'>OK</MenuItem>
				</Select>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(StateSelection);
