import React from "react";
import Sidebar from './Sidebar';
import Mapper from './Mapper';
import './App.css';

export default class App extends React.Component {

	componentDidMount(){
		fetch('/checkToken')
		.then(response => {
			if(response.status !== 200){
				this.props.history.push('/login');
			}
		});
	}

	render(){
		return (
			<div>
				<Sidebar />
				<Mapper />
			</div>
		);
	}
}
