import React from "react";
import { connect } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { SET_HISPANIC } from '../reducers/target';

const mapStateToProps = state => ({
	hispanic: state.target.hispanic
});

const mapDispatchToProps = dispatch =>({
	onHispanicSet: (value) => dispatch({
		type: SET_HISPANIC,
		hispanic: value
	}),
});

export class Hispanic extends React.Component {
	constructor(props){
		super(props);
		this.handleChange = this.handleChange.bind(this);
	}
	static defaultProps = {
		hispanic:false
	}

	handleChange(event){
		this.props.onHispanicSet(event.target.checked)
	}

	render(){
		return (
			<div className="filter">
				<FormControlLabel 
					control={
						<Switch name="hispanic" 
							checked={this.props.hispanic} 
							onChange={this.handleChange} />
					}
					label="Tagged Hispanic" />
			</div>
		);
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(Hispanic);