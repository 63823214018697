import React from "react";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { StaticMap } from 'react-map-gl';
import { useParams, Redirect } from "react-router-dom";

const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1Ijoic2dzIiwiYSI6IkhWVkxqRGcifQ.EEFI5awzJOeJIesc59epaQ';
const MAPBOX_STYLE = 'mapbox://styles/sgs/cjt63rq3z05nt1fmgj19wrx77';

export default class Reset extends React.Component {
	constructor(props){
		super(props);
		this.state={
			email:'',
			pass:'',
			pass2:'',
			hash:'',
		}
		this.onSubmit = this.onSubmit.bind(this);
		this.onRequest = this.onRequest.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}

	handleInputChange(event){
		const { value, name } = event.target;
		this.setState({
			[name]: value
		});
	}

	componentDidMount(){
		const token = this.props.match.params.token;

		if(token !== 'password' && token !== 'sent' && token !== 'success'){
			this.setState({hash:token});
		}
	}

	onRequest(event){
		event.preventDefault();
		fetch('/passwordrequest',{
			method: 'POST',
			headers:{'Content-Type':'application/json'},
			body: JSON.stringify(this.state)
		}).then(response =>{
			if(response.status === 200){
				this.props.history.push('/reset/sent');
			}
		}).catch(error => {
			console.log(error);
		})
	}

	onSubmit(event){
	    event.preventDefault();
	    fetch('/passwordreset',{
	    	method:'POST',
	    	headers:{'Content-Type':'application/json'},
	    	body: JSON.stringify(this.state),
	    }).then( response =>{
	    	if(response.status === 200){
	    		console.log(response);
	    		this.props.history.push('/reset/success');
	    	} else {
	    		const error = new Error(response.error);
	    		throw error;
	    	}
	    }).catch(error => {
	    	alert("Error: please double check that your passwords match and link hasn't expired");
	    });
	}

	formSwap(){
		const token = this.props.match.params.token;
		const request = (
		<div>
			<h2>Enter your email address</h2>
			<form className='wrapper' onSubmit={this.onRequest}>
				<TextField label="Email" variant="outlined" type="email" required fullWidth name="email" onChange={this.handleInputChange} />
				<Button type="submit" fullWidth size="large" variant="contained" color="primary">Send a Password Reset Email</Button>
	    	</form>
	    </div>);

		const sent = (
		<div>
			<h2>Password Reset Link Sent</h2>
			<p className='wrapper'>Thanks, you should receive an email message with a link. Please make sure info@servingassociations.com is allowed to send you email.</p>
		</div>);

		const hash = (
		<div>
			<h2>Reset your password</h2>
			<form className='wrapper' onSubmit={this.onSubmit}>
				<input type="hidden" value={token} name="hash" />
				<TextField type="password" label="New Password" variant="outlined" required fullWidth name="pass" onChange={this.handleInputChange} />
				<TextField type="password" label="Verify Password" variant="outlined" required fullWidth name="pass2" onChange={this.handleInputChange} error={ (this.state.pass !== this.state.pass2) }/>
				<Button type="submit" fullWidth size="large" variant="contained" color="primary" >Change Password</Button>
	    	</form>
	    </div>);

		const success = (
		<div>
			<h2>Password Changed</h2>
			<div className='wrapper'>
				<Button href="/login" fullWidth size="large" variant="contained" color="primary" >Return to login page</Button>
			</div>
		</div>);

		if(token === 'password'){
			return request;
		}
		if(token === 'sent'){
			return sent;
		}
		if(token === 'success'){
			return success;
		}
		return hash;
	}
	

	render(){
		
		return (
			<div>
				<div id="login">
					<div className="brand">
						<img src="/logo.png" alt='' />
					</div>
				{this.formSwap()}
				</div>
				<StaticMap reuseMaps width={'100vw'} height={'100vh'} latitude={31.409912} longitude={-99.09668} zoom={6} mapStyle={MAPBOX_STYLE} mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}/>
			</div>
		);
	}
}