import React from "react";
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';
import { SET_DATE_RANGE } from '../reducers/target';

const mapStateToProps = state =>({
	contrib_date: state.target.contrib_date
});

const mapDispatchToProps = dispatch => ({
	onDateSet: (dates) => dispatch({ 
		type: SET_DATE_RANGE, 
		contrib_date:dates
	}),
});

const maxDate = new Date("2100-01-01");
const minDate = new Date("1900-01-01");

export class DateRange extends React.Component {
	constructor(props){
		super(props);
		this.handleChange = this.handleChange.bind(this);
	}
	static defaultProps = {
		contrib_date:{
			min:null,
			max:null
		}
	}

	handleChange(date, whichOne){
		if(whichOne === 'min'){
			this.props.onDateSet({
				...this.props.contrib_date,
				min:date
			})
		} else {
			this.props.onDateSet({
				...this.props.contrib_date,
				max:date
			})
		}
	}

	render(){
		return (
			<div className="filter">
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<Grid container spacing={1} alignItems="center">
					<Grid item xs>
					<DatePicker
						id="min-date"
						autoOk
						clearable
						orientation="portrait"
						label="Start Date"
						InputLabelProps={{shrink: true,}}
						format="MM/dd/yyyy"
						value={this.props.contrib_date.min}
						maxDate={this.props.contrib_date.max?this.props.contrib_date.max:maxDate}
						onChange={(date) => {this.handleChange(date,"min")}}
						TextFieldComponent={(params)=> <TextField {...params} size="small" variant="outlined" />}
					/>
					</Grid>
					<Grid item><Typography> – </Typography></Grid>
					<Grid item xs>
					<DatePicker
						id="max-date"
						autoOk
						clearable
						orientation="portrait"
						label="End Date"
						InputLabelProps={{shrink: true,}}
						format="MM/dd/yyyy"
						value={this.props.contrib_date.max}
						minDate={this.props.contrib_date.min?this.props.contrib_date.min:minDate}
						onChange={this.handleChange}
						TextFieldComponent={(params)=> <TextField {...params} size="small" variant="outlined" />}
					/>
					</Grid>
				</Grid>
			</MuiPickersUtilsProvider>
			</div>
		);
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(DateRange);