import React from "react";
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { SET_AGE_RANGE } from '../reducers/target';

const mapStateToProps = state => ({
	age: state.target.age
});

const mapDispatchToProps = dispatch => ({
	onAgeRangeSet: (agerange) => dispatch({
		type: SET_AGE_RANGE,
		age: agerange
	}),
});

export class AgeRange extends React.Component {
	constructor(props){
		super(props);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event){
		if(event.target.id === "age-min"){
			this.props.onAgeRangeSet({
				...this.props.age,
				min: parseInt(event.target.value)
			});
		} else {
			this.props.onAgeRangeSet({
				...this.props.age,
				max: parseInt(event.target.value)
			});
		}
	}
	// checkMinMax(){
	// 	this.setState(state => {
	// 		if(state.ageMin === '' || state.ageMax === ''){
	// 			return state;
	// 		}
	// 		var max = parseInt(state.ageMax)+0;
	// 		var min = parseInt(state.ageMin)+0;
	// 		if(min > max){
	// 			return {ageMin: max, ageMax: min };
	// 		} else {
	// 			return state;
	// 		}
	// 	});
	// }

	render(){
		return (
			<div className="filter">
				<Grid container spacing={1} alignItems="center">
					<Grid item xs>
						<TextField
							id="age-min"
							variant="outlined"
							label="Age Min"
							InputLabelProps={{shrink: true,}}
							value={this.props.age.min}
							onChange={this.handleChange}
							InputProps={{
								endAdornment: <InputAdornment position="end">yrs.</InputAdornment>,
								type:"number"
							}}
							size="small"
						/>
					</Grid>
					<Grid item>
						<Typography> – </Typography>
					</Grid>
					<Grid item xs>
						<TextField
							id="age-max"
							variant="outlined"
							label="Age Max"
							InputLabelProps={{shrink: true,}}
							value={this.props.age.max}
							onChange={this.handleChange}
							InputProps={{
								endAdornment: <InputAdornment position="end">yrs.</InputAdornment>,
								type:"number"
							}}
							size="small"
						/>
					</Grid>
				</Grid>
			</div>
		);
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(AgeRange);