import React from "react";
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import { SET_AMOUNT_RANGE } from '../reducers/target';

const mapStateToProps = state => ({
	amount: state.target.amount,
});

const mapDispatchToProps = dispatch => ({
	onAmountSet: (amountRange) => dispatch({
		type: SET_AMOUNT_RANGE, 
		amount:amountRange
	}),
});

export class AmountRange extends React.Component {
	constructor(props){
		super(props);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event){
		let val = isNaN(parseInt(event.target.value)) ? '' : parseInt(event.target.value);
		if(event.target.id === "amount-min"){
			this.props.onAmountSet({
				...this.props.amount,
				min: val
			})
		} else {
			this.props.onAmountSet({
				...this.props.amount,
				max: val
			});	
		}
	}
	// this should be done at the db level or when updatemap is submitted
	// checkMinMax(){
	// 	this.setState(state => {
	// 		if(state.min === '' || state.max === ''){
	// 			return state;
	// 		}
	// 		var max = parseInt(state.max)+0;
	// 		var min = parseInt(state.min)+0;
	// 		if(min > max){
	// 			return {min: max, max: min };
	// 		} else {
	// 			return state;
	// 		}
	// 	});
	// }

	render(){
		return (
			<div className="filter">
				<Grid container spacing={1} alignItems="center">
					<Grid item xs>
						<TextField
						id="amount-min"
						variant="outlined"
						label="Amount Min"
						InputProps={{
							startAdornment: <InputAdornment position="start">$</InputAdornment>,
							type:"number"
						}}
						size="small"
						onChange={this.handleChange}
						value={this.props.amount.min}
					/>
					</Grid>
					<Grid item>
						<Typography> – </Typography>
					</Grid>
					<Grid item xs>
						<TextField
						id="amount-max"
						variant="outlined"
						label="Amount Max"
						InputProps={{
							startAdornment: <InputAdornment position="start">$</InputAdornment>,
							type:"number"
						}}
						size="small"
						onChange={this.handleChange}
						value={this.props.amount.max}
						/>
					</Grid>
				</Grid>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AmountRange);
