import React from "react";
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { SET_CONTRIBUTOR } from '../reducers/target';

const mapStateToProps = state => ({
  contributor: state.target.contributor
});

const mapDispatchToProps = dispatch => ({
  onContributorSet: (value) =>
    dispatch({ type: SET_CONTRIBUTOR, contributor:value}),
});

export class Contributor extends React.Component {
	constructor(props){
		super(props);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event){
		this.props.onContributorSet(event.target.value)
	}

	render(){
		return (
			<div className="filter">
				<TextField
					label="Contributor"
					variant="outlined"
					size="small"
					onChange={this.handleChange}
					value={this.props.contributor}
				/>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Contributor);
