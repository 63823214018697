import React from "react";
import { connect } from 'react-redux';
import DeckGL from '@deck.gl/react';
import { StaticMap } from 'react-map-gl'
import { ScatterplotLayer } from '@deck.gl/layers';
import { scaleQuantile } from 'd3-scale';
import Legend from './Legend';

const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1Ijoic2dzIiwiYSI6IkhWVkxqRGcifQ.EEFI5awzJOeJIesc59epaQ';
const MAPBOX_STYLE = 'mapbox://styles/sgs/cjt63rq3z05nt1fmgj19wrx77';

const mapStateToProps = state => ({
	data: state.map.collection,
	view: state.map.view,
});

export class Mapper extends React.Component {

	render(){
		const color = scaleQuantile()
		.domain(this.props.data.map(item => parseFloat(item.amount)))
		.range([[254,224,139],[255,255,191],[217,239,139],[166,217,106],[102,189,99],[26,152,80]]);

		const layers = [
			new ScatterplotLayer({
				id:'basemap',
				data: this.props.data,
				radiusScale:10,
				radiusMinPixels:1.5,
				getPosition: d => [parseFloat(d.longitude), parseFloat(d.latitude), 0],
				getFillColor: d => color(parseFloat(d.amount)),
				getRadius:3,
			})
		];

		return (
			<div id="map">
				<Legend breaks={ color.quantiles() }/>
				<DeckGL layers={layers} initialViewState={this.props.view} controller={true}>
					<StaticMap reuseMaps mapStyle={MAPBOX_STYLE} mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}/>
				</DeckGL>
			</div>
		);
	}
}

export default connect(mapStateToProps, null)(Mapper);