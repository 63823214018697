import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import './index.css';
import App from './component/App';
import Login from './component/Login';
import Reset from './component/Reset';
import withAuth from './component/withAuth';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <Provider store={store}>
  	<BrowserRouter>
		<Switch>
			<Route exact path="/" component={withAuth(App)} />
			<Route path="/login" component={Login} />
			<Route path="/reset/:token" component={Reset} />
		</Switch>
	</BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
