import React from "react";
import { connect } from 'react-redux';
import StateSelection from "./StateSelection";
import Contributee from "./Contributee";
import Contributor from "./Contributor";
import PartySlider from "./PartySlider";
import AmountRange from "./AmountRange";
import DateRange from "./DateRange";
import Geography from "./Geography";
import AgeRange from "./AgeRange";
import Hispanic from "./Hispanic";
import Gender from "./Gender";
import FEC from "./FEC";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { UPDATE_MAP } from '../reducers/map';
import { format } from 'd3-format';


const mapStateToProps = state =>({
	target:state.target,
	collection:state.map.collection
});

const mapDispatchToProps = dispatch => ({
	onUpdate: (result) => dispatch({
		type: UPDATE_MAP,
		collection:result,
	})
});

function downloadBlob(blob, filename){
	const url = URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.href = url;
	a.download = filename || 'download.csv';
	a.click();
	a.remove();
}

//TODO handleClick request update based on props.target object

export class Sidebar extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			loading: false,
			downloading:false,
		};
		this.handleUpdate = this.handleUpdate.bind(this);
		this.handleDL = this.handleDL.bind(this);
	}

	handleUpdate(){
		let payload = this.props.target;
		this.setState({loading:true});
		fetch('/updatemap',{
			method:'POST',
			headers:{'Content-Type':'application/json'},
			body: JSON.stringify(payload)
		})
		.then( res => res.json())
		.then( data => {
			if(!data.errors){
				this.props.onUpdate(data);
			}
			this.setState({loading:false})
		}).catch(error => {
			this.setState({loading:false});
			console.log(error);;
		});

	}

	handleDL(){
		this.setState({downloading:true})
		let payload = this.props.target;
		fetch('/download',{
			method:'POST',
			headers:{'Content-Type':'application/json'},
			body:JSON.stringify(payload)
		}).then(res => res.blob())
		.then(csv => {
			downloadBlob(csv, 'download.csv');
			this.setState({downloading:false})
		}).catch( error => {
			this.setState({downloading:false})
			console.log(error);
		});

	}

	render(){
		return (
			<div className="sidebar">
				<div className="brand">
					<img src="/logo.png" alt=''/>
					<StateSelection />
				</div>
				<div className="filterbox">
					<Contributee />
					<Contributor />
					<PartySlider />
					<AmountRange />
					<DateRange />
					<Geography />
					<AgeRange />
					<Hispanic />
					<Gender />
					<FEC />
					<Grid container spacing={1}>
						<Grid item xs>
							<Button variant="outlined" color="primary" disabled={this.state.loading} onClick={this.handleUpdate} fullWidth>Update Map</Button>
						</Grid>
						<Grid item xs>
							<Button variant="outlined" color="secondary" disabled={this.state.downloading} onClick={this.handleDL} fullWidth>{ this.state.downloading ? <CircularProgress size="1.5rem" /> : 'Download' }</Button>
						</Grid>
					</Grid>
					<div className="filter"><span> Count: { this.state.loading ? <CircularProgress size="1em" /> : format(',')(this.props.collection.length)}</span></div>
				</div>
				<div className="adminbar">
					<a href="/logout">logout</a>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(Sidebar);
