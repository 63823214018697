export const SET_VIEW = 'SET_VIEW';
export const UPDATE_MAP = 'UPDATE_MAP';

const initalState = {
	view:{
		longitude:-99.09668,
		latitude:31.409912,
		zoom:6
	},
	collection:[],
}

export default function map(state = initalState, action){
	switch(action.type){
		case SET_VIEW:
			return {	
				...state,
				view: action.view
			};
		case UPDATE_MAP:
			return {
				...state,
				collection: action.collection
			};
		default:
			return state;
	}
};