import React from "react";
import {format} from 'd3-format';

export default class Legend extends React.Component {
	render(){
		const f = format("$,.2f")
		return(
			<div id="Legend" style={(typeof this.props.breaks[0] == 'undefined')? {display:'none'} : {display:'block'}}>
				<ul>
					<li><span style={{backgroundColor:'#1a9850'}}></span> Greater than {f(this.props.breaks[4])}</li>
					<li><span style={{backgroundColor:'#66bd63'}}></span> {f(this.props.breaks[3])} - {f(this.props.breaks[4])}</li>
					<li><span style={{backgroundColor:'#a6d96a'}}></span> {f(this.props.breaks[2])} - {f(this.props.breaks[3])}</li>
					<li><span style={{backgroundColor:'#d9ef8b'}}></span> {f(this.props.breaks[1])} - {f(this.props.breaks[2])}</li>
					<li><span style={{backgroundColor:'#ffffbf'}}></span> {f(this.props.breaks[0])} - {f(this.props.breaks[1])}</li>
					<li><span style={{backgroundColor:'#fee08b'}}></span> Less than {f(this.props.breaks[0])}</li>
				</ul>
			</div>
		);
	}
}