import React from "react";
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { SET_GENDER } from '../reducers/target';

const mapStateToProps = state => ({
	gender: state.target.gender,
	st: state.target.st
});

const mapDispatchToProps = dispatch =>({
	onGenderSet: (value) => dispatch({
		type: SET_GENDER,
		gender: value,
	}),
});
export class Gender extends React.Component {
	constructor(props){
		super(props);
		this.handleChange = this.handleChange.bind(this);
	}
	static defaultProps = {
		gender:{
			male:false,
			female:false,
			unknown:false,
		}
	}

	handleChange(event){
		this.props.onGenderSet({
			...this.props.gender,
			[event.target.name]:event.target.checked
		})
	}

	render(){
		if(this.props.st === 'ok' || this.props.st === 'ar'){
			return null;
		} else {
		return (
			<div className="filter">
				<FormControl component="fieldset">
					<FormLabel>Gender</FormLabel>
					<FormGroup row>
						<FormControlLabel control={<Checkbox name="male" checked={this.props.gender.male} onChange={this.handleChange} />} label="M" />
						<FormControlLabel control={<Checkbox name="female" checked={this.props.gender.female} onChange={this.handleChange} />} label="F" />
						<FormControlLabel control={<Checkbox name="unknown" checked={this.props.gender.unknown} onChange={this.handleChange} />} label="Unknown" />
					</FormGroup>
				</FormControl>
			</div>
		);
		} 
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(Gender);