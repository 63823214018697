import React from "react";
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { SET_GEOGRAPHY } from '../reducers/target';

const mapStateToProps = state => ({
	geography: state.target.geography,
	st:state.target.st
});

const mapDispatchToProps = dispatch =>({
	onGeographySet: (geos) => dispatch({
		type: SET_GEOGRAPHY,
		geography: geos
	}),
});

export class Geography extends React.Component {
	constructor(props){
		super(props);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event, newValue){
		this.props.onGeographySet(newValue)
	}

	render(){
		var options = geolist[this.props.st];
		return (
			<div className="filter">
				<Autocomplete 
					multiple
					options={options}
					groupBy={(option)=> option.type}
					getOptionLabel={(option)=> option.label}
					onChange={this.handleChange}
					renderInput={(params) => 
						<TextField {...params} 
						label="Political Geography" 
						variant="outlined" 
						size="small"/>
					}
				/>
			</div>
		);
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(Geography);

const geolist=[];

geolist['tx'] = [
{type:'CD', label:'CD1', geo:'1'},
{type:'CD', label:'CD2', geo:'2'},
{type:'CD', label:'CD3', geo:'3'},
{type:'CD', label:'CD4', geo:'4'},
{type:'CD', label:'CD5', geo:'5'},
{type:'CD', label:'CD6', geo:'6'},
{type:'CD', label:'CD7', geo:'7'},
{type:'CD', label:'CD8', geo:'8'},
{type:'CD', label:'CD9', geo:'9'},
{type:'CD', label:'CD10', geo:'10'},
{type:'CD', label:'CD11', geo:'11'},
{type:'CD', label:'CD12', geo:'12'},
{type:'CD', label:'CD13', geo:'13'},
{type:'CD', label:'CD14', geo:'14'},
{type:'CD', label:'CD15', geo:'15'},
{type:'CD', label:'CD16', geo:'16'},
{type:'CD', label:'CD17', geo:'17'},
{type:'CD', label:'CD18', geo:'18'},
{type:'CD', label:'CD19', geo:'19'},
{type:'CD', label:'CD20', geo:'20'},
{type:'CD', label:'CD21', geo:'21'},
{type:'CD', label:'CD22', geo:'22'},
{type:'CD', label:'CD23', geo:'23'},
{type:'CD', label:'CD24', geo:'24'},
{type:'CD', label:'CD25', geo:'25'},
{type:'CD', label:'CD26', geo:'26'},
{type:'CD', label:'CD27', geo:'27'},
{type:'CD', label:'CD28', geo:'28'},
{type:'CD', label:'CD29', geo:'29'},
{type:'CD', label:'CD30', geo:'30'},
{type:'CD', label:'CD31', geo:'31'},
{type:'CD', label:'CD32', geo:'32'},
{type:'CD', label:'CD33', geo:'33'},
{type:'CD', label:'CD34', geo:'34'},
{type:'CD', label:'CD35', geo:'35'},
{type:'CD', label:'CD36', geo:'36'},
{type:'CD', label:'CD37', geo:'37'},
{type:'CD', label:'CD38', geo:'38'},
{type:'SD', label:'SD1', geo:'1'},
{type:'SD', label:'SD2', geo:'2'},
{type:'SD', label:'SD3', geo:'3'},
{type:'SD', label:'SD4', geo:'4'},
{type:'SD', label:'SD5', geo:'5'},
{type:'SD', label:'SD6', geo:'6'},
{type:'SD', label:'SD7', geo:'7'},
{type:'SD', label:'SD8', geo:'8'},
{type:'SD', label:'SD9', geo:'9'},
{type:'SD', label:'SD10', geo:'10'},
{type:'SD', label:'SD11', geo:'11'},
{type:'SD', label:'SD12', geo:'12'},
{type:'SD', label:'SD13', geo:'13'},
{type:'SD', label:'SD14', geo:'14'},
{type:'SD', label:'SD15', geo:'15'},
{type:'SD', label:'SD16', geo:'16'},
{type:'SD', label:'SD17', geo:'17'},
{type:'SD', label:'SD18', geo:'18'},
{type:'SD', label:'SD19', geo:'19'},
{type:'SD', label:'SD20', geo:'20'},
{type:'SD', label:'SD21', geo:'21'},
{type:'SD', label:'SD22', geo:'22'},
{type:'SD', label:'SD23', geo:'23'},
{type:'SD', label:'SD24', geo:'24'},
{type:'SD', label:'SD25', geo:'25'},
{type:'SD', label:'SD26', geo:'26'},
{type:'SD', label:'SD27', geo:'27'},
{type:'SD', label:'SD28', geo:'28'},
{type:'SD', label:'SD29', geo:'29'},
{type:'SD', label:'SD30', geo:'30'},
{type:'SD', label:'SD31', geo:'31'},
{type:'HD', label:'HD1', geo:'1'},
{type:'HD', label:'HD2', geo:'2'},
{type:'HD', label:'HD3', geo:'3'},
{type:'HD', label:'HD4', geo:'4'},
{type:'HD', label:'HD5', geo:'5'},
{type:'HD', label:'HD6', geo:'6'},
{type:'HD', label:'HD7', geo:'7'},
{type:'HD', label:'HD8', geo:'8'},
{type:'HD', label:'HD9', geo:'9'},
{type:'HD', label:'HD10', geo:'10'},
{type:'HD', label:'HD11', geo:'11'},
{type:'HD', label:'HD12', geo:'12'},
{type:'HD', label:'HD13', geo:'13'},
{type:'HD', label:'HD14', geo:'14'},
{type:'HD', label:'HD15', geo:'15'},
{type:'HD', label:'HD16', geo:'16'},
{type:'HD', label:'HD17', geo:'17'},
{type:'HD', label:'HD18', geo:'18'},
{type:'HD', label:'HD19', geo:'19'},
{type:'HD', label:'HD20', geo:'20'},
{type:'HD', label:'HD21', geo:'21'},
{type:'HD', label:'HD22', geo:'22'},
{type:'HD', label:'HD23', geo:'23'},
{type:'HD', label:'HD24', geo:'24'},
{type:'HD', label:'HD25', geo:'25'},
{type:'HD', label:'HD26', geo:'26'},
{type:'HD', label:'HD27', geo:'27'},
{type:'HD', label:'HD28', geo:'28'},
{type:'HD', label:'HD29', geo:'29'},
{type:'HD', label:'HD30', geo:'30'},
{type:'HD', label:'HD31', geo:'31'},
{type:'HD', label:'HD32', geo:'32'},
{type:'HD', label:'HD33', geo:'33'},
{type:'HD', label:'HD34', geo:'34'},
{type:'HD', label:'HD35', geo:'35'},
{type:'HD', label:'HD36', geo:'36'},
{type:'HD', label:'HD37', geo:'37'},
{type:'HD', label:'HD38', geo:'38'},
{type:'HD', label:'HD39', geo:'39'},
{type:'HD', label:'HD40', geo:'40'},
{type:'HD', label:'HD41', geo:'41'},
{type:'HD', label:'HD42', geo:'42'},
{type:'HD', label:'HD43', geo:'43'},
{type:'HD', label:'HD44', geo:'44'},
{type:'HD', label:'HD45', geo:'45'},
{type:'HD', label:'HD46', geo:'46'},
{type:'HD', label:'HD47', geo:'47'},
{type:'HD', label:'HD48', geo:'48'},
{type:'HD', label:'HD49', geo:'49'},
{type:'HD', label:'HD50', geo:'50'},
{type:'HD', label:'HD51', geo:'51'},
{type:'HD', label:'HD52', geo:'52'},
{type:'HD', label:'HD53', geo:'53'},
{type:'HD', label:'HD54', geo:'54'},
{type:'HD', label:'HD55', geo:'55'},
{type:'HD', label:'HD56', geo:'56'},
{type:'HD', label:'HD57', geo:'57'},
{type:'HD', label:'HD58', geo:'58'},
{type:'HD', label:'HD59', geo:'59'},
{type:'HD', label:'HD60', geo:'60'},
{type:'HD', label:'HD61', geo:'61'},
{type:'HD', label:'HD62', geo:'62'},
{type:'HD', label:'HD63', geo:'63'},
{type:'HD', label:'HD64', geo:'64'},
{type:'HD', label:'HD65', geo:'65'},
{type:'HD', label:'HD66', geo:'66'},
{type:'HD', label:'HD67', geo:'67'},
{type:'HD', label:'HD68', geo:'68'},
{type:'HD', label:'HD69', geo:'69'},
{type:'HD', label:'HD70', geo:'70'},
{type:'HD', label:'HD71', geo:'71'},
{type:'HD', label:'HD72', geo:'72'},
{type:'HD', label:'HD73', geo:'73'},
{type:'HD', label:'HD74', geo:'74'},
{type:'HD', label:'HD75', geo:'75'},
{type:'HD', label:'HD76', geo:'76'},
{type:'HD', label:'HD77', geo:'77'},
{type:'HD', label:'HD78', geo:'78'},
{type:'HD', label:'HD79', geo:'79'},
{type:'HD', label:'HD80', geo:'80'},
{type:'HD', label:'HD81', geo:'81'},
{type:'HD', label:'HD82', geo:'82'},
{type:'HD', label:'HD83', geo:'83'},
{type:'HD', label:'HD84', geo:'84'},
{type:'HD', label:'HD85', geo:'85'},
{type:'HD', label:'HD86', geo:'86'},
{type:'HD', label:'HD87', geo:'87'},
{type:'HD', label:'HD88', geo:'88'},
{type:'HD', label:'HD89', geo:'89'},
{type:'HD', label:'HD90', geo:'90'},
{type:'HD', label:'HD91', geo:'91'},
{type:'HD', label:'HD92', geo:'92'},
{type:'HD', label:'HD93', geo:'93'},
{type:'HD', label:'HD94', geo:'94'},
{type:'HD', label:'HD95', geo:'95'},
{type:'HD', label:'HD96', geo:'96'},
{type:'HD', label:'HD97', geo:'97'},
{type:'HD', label:'HD98', geo:'98'},
{type:'HD', label:'HD99', geo:'99'},
{type:'HD', label:'HD100', geo:'100'},
{type:'HD', label:'HD101', geo:'101'},
{type:'HD', label:'HD102', geo:'102'},
{type:'HD', label:'HD103', geo:'103'},
{type:'HD', label:'HD104', geo:'104'},
{type:'HD', label:'HD105', geo:'105'},
{type:'HD', label:'HD106', geo:'106'},
{type:'HD', label:'HD107', geo:'107'},
{type:'HD', label:'HD108', geo:'108'},
{type:'HD', label:'HD109', geo:'109'},
{type:'HD', label:'HD110', geo:'110'},
{type:'HD', label:'HD111', geo:'111'},
{type:'HD', label:'HD112', geo:'112'},
{type:'HD', label:'HD113', geo:'113'},
{type:'HD', label:'HD114', geo:'114'},
{type:'HD', label:'HD115', geo:'115'},
{type:'HD', label:'HD116', geo:'116'},
{type:'HD', label:'HD117', geo:'117'},
{type:'HD', label:'HD118', geo:'118'},
{type:'HD', label:'HD119', geo:'119'},
{type:'HD', label:'HD120', geo:'120'},
{type:'HD', label:'HD121', geo:'121'},
{type:'HD', label:'HD122', geo:'122'},
{type:'HD', label:'HD123', geo:'123'},
{type:'HD', label:'HD124', geo:'124'},
{type:'HD', label:'HD125', geo:'125'},
{type:'HD', label:'HD126', geo:'126'},
{type:'HD', label:'HD127', geo:'127'},
{type:'HD', label:'HD128', geo:'128'},
{type:'HD', label:'HD129', geo:'129'},
{type:'HD', label:'HD130', geo:'130'},
{type:'HD', label:'HD131', geo:'131'},
{type:'HD', label:'HD132', geo:'132'},
{type:'HD', label:'HD133', geo:'133'},
{type:'HD', label:'HD134', geo:'134'},
{type:'HD', label:'HD135', geo:'135'},
{type:'HD', label:'HD136', geo:'136'},
{type:'HD', label:'HD137', geo:'137'},
{type:'HD', label:'HD138', geo:'138'},
{type:'HD', label:'HD139', geo:'139'},
{type:'HD', label:'HD140', geo:'140'},
{type:'HD', label:'HD141', geo:'141'},
{type:'HD', label:'HD142', geo:'142'},
{type:'HD', label:'HD143', geo:'143'},
{type:'HD', label:'HD144', geo:'144'},
{type:'HD', label:'HD145', geo:'145'},
{type:'HD', label:'HD146', geo:'146'},
{type:'HD', label:'HD147', geo:'147'},
{type:'HD', label:'HD148', geo:'148'},
{type:'HD', label:'HD149', geo:'149'},
{type:'HD', label:'HD150', geo:'150'},
{type:'County', label:'ANDERSON COUNTY', geo:'ANDERSON'},
{type:'County', label:'ANDREWS COUNTY', geo:'ANDREWS'},
{type:'County', label:'ANGELINA COUNTY', geo:'ANGELINA'},
{type:'County', label:'ARANSAS COUNTY', geo:'ARANSAS'},
{type:'County', label:'ARCHER COUNTY', geo:'ARCHER'},
{type:'County', label:'ARMSTRONG COUNTY', geo:'ARMSTRONG'},
{type:'County', label:'ATASCOSA COUNTY', geo:'ATASCOSA'},
{type:'County', label:'AUSTIN COUNTY', geo:'AUSTIN'},
{type:'County', label:'BAILEY COUNTY', geo:'BAILEY'},
{type:'County', label:'BANDERA COUNTY', geo:'BANDERA'},
{type:'County', label:'BASTROP COUNTY', geo:'BASTROP'},
{type:'County', label:'BAYLOR COUNTY', geo:'BAYLOR'},
{type:'County', label:'BEE COUNTY', geo:'BEE'},
{type:'County', label:'BELL COUNTY', geo:'BELL'},
{type:'County', label:'BEXAR COUNTY', geo:'BEXAR'},
{type:'County', label:'BLANCO COUNTY', geo:'BLANCO'},
{type:'County', label:'BORDEN COUNTY', geo:'BORDEN'},
{type:'County', label:'BOSQUE COUNTY', geo:'BOSQUE'},
{type:'County', label:'BOWIE COUNTY', geo:'BOWIE'},
{type:'County', label:'BRAZORIA COUNTY', geo:'BRAZORIA'},
{type:'County', label:'BRAZOS COUNTY', geo:'BRAZOS'},
{type:'County', label:'BREWSTER COUNTY', geo:'BREWSTER'},
{type:'County', label:'BRISCOE COUNTY', geo:'BRISCOE'},
{type:'County', label:'BROOKS COUNTY', geo:'BROOKS'},
{type:'County', label:'BROWN COUNTY', geo:'BROWN'},
{type:'County', label:'BURLESON COUNTY', geo:'BURLESON'},
{type:'County', label:'BURNET COUNTY', geo:'BURNET'},
{type:'County', label:'CALDWELL COUNTY', geo:'CALDWELL'},
{type:'County', label:'CALHOUN COUNTY', geo:'CALHOUN'},
{type:'County', label:'CALLAHAN COUNTY', geo:'CALLAHAN'},
{type:'County', label:'CAMERON COUNTY', geo:'CAMERON'},
{type:'County', label:'CAMP COUNTY', geo:'CAMP'},
{type:'County', label:'CARSON COUNTY', geo:'CARSON'},
{type:'County', label:'CASS COUNTY', geo:'CASS'},
{type:'County', label:'CASTRO COUNTY', geo:'CASTRO'},
{type:'County', label:'CHAMBERS COUNTY', geo:'CHAMBERS'},
{type:'County', label:'CHEROKEE COUNTY', geo:'CHEROKEE'},
{type:'County', label:'CHILDRESS COUNTY', geo:'CHILDRESS'},
{type:'County', label:'CLAY COUNTY', geo:'CLAY'},
{type:'County', label:'COCHRAN COUNTY', geo:'COCHRAN'},
{type:'County', label:'COKE COUNTY', geo:'COKE'},
{type:'County', label:'COLEMAN COUNTY', geo:'COLEMAN'},
{type:'County', label:'COLLIN COUNTY', geo:'COLLIN'},
{type:'County', label:'COLLINGSWORTH COUNTY', geo:'COLLINGSWORTH'},
{type:'County', label:'COLORADO COUNTY', geo:'COLORADO'},
{type:'County', label:'COMAL COUNTY', geo:'COMAL'},
{type:'County', label:'COMANCHE COUNTY', geo:'COMANCHE'},
{type:'County', label:'CONCHO COUNTY', geo:'CONCHO'},
{type:'County', label:'COOKE COUNTY', geo:'COOKE'},
{type:'County', label:'CORYELL COUNTY', geo:'CORYELL'},
{type:'County', label:'COTTLE COUNTY', geo:'COTTLE'},
{type:'County', label:'CRANE COUNTY', geo:'CRANE'},
{type:'County', label:'CROCKETT COUNTY', geo:'CROCKETT'},
{type:'County', label:'CROSBY COUNTY', geo:'CROSBY'},
{type:'County', label:'CULBERSON COUNTY', geo:'CULBERSON'},
{type:'County', label:'DALLAM COUNTY', geo:'DALLAM'},
{type:'County', label:'DALLAS COUNTY', geo:'DALLAS'},
{type:'County', label:'DAWSON COUNTY', geo:'DAWSON'},
{type:'County', label:'DEAF SMITH COUNTY', geo:'DEAF SMITH'},
{type:'County', label:'DELTA COUNTY', geo:'DELTA'},
{type:'County', label:'DENTON COUNTY', geo:'DENTON'},
{type:'County', label:'DEWITT COUNTY', geo:'DEWITT'},
{type:'County', label:'DICKENS COUNTY', geo:'DICKENS'},
{type:'County', label:'DIMMIT COUNTY', geo:'DIMMIT'},
{type:'County', label:'DONLEY COUNTY', geo:'DONLEY'},
{type:'County', label:'DUVAL COUNTY', geo:'DUVAL'},
{type:'County', label:'EASTLAND COUNTY', geo:'EASTLAND'},
{type:'County', label:'ECTOR COUNTY', geo:'ECTOR'},
{type:'County', label:'EDWARDS COUNTY', geo:'EDWARDS'},
{type:'County', label:'ELLIS COUNTY', geo:'ELLIS'},
{type:'County', label:'EL PASO COUNTY', geo:'EL PASO'},
{type:'County', label:'ERATH COUNTY', geo:'ERATH'},
{type:'County', label:'FALLS COUNTY', geo:'FALLS'},
{type:'County', label:'FANNIN COUNTY', geo:'FANNIN'},
{type:'County', label:'FAYETTE COUNTY', geo:'FAYETTE'},
{type:'County', label:'FISHER COUNTY', geo:'FISHER'},
{type:'County', label:'FLOYD COUNTY', geo:'FLOYD'},
{type:'County', label:'FOARD COUNTY', geo:'FOARD'},
{type:'County', label:'FORT BEND COUNTY', geo:'FORT BEND'},
{type:'County', label:'FRANKLIN COUNTY', geo:'FRANKLIN'},
{type:'County', label:'FREESTONE COUNTY', geo:'FREESTONE'},
{type:'County', label:'FRIO COUNTY', geo:'FRIO'},
{type:'County', label:'GAINES COUNTY', geo:'GAINES'},
{type:'County', label:'GALVESTON COUNTY', geo:'GALVESTON'},
{type:'County', label:'GARZA COUNTY', geo:'GARZA'},
{type:'County', label:'GILLESPIE COUNTY', geo:'GILLESPIE'},
{type:'County', label:'GLASSCOCK COUNTY', geo:'GLASSCOCK'},
{type:'County', label:'GOLIAD COUNTY', geo:'GOLIAD'},
{type:'County', label:'GONZALES COUNTY', geo:'GONZALES'},
{type:'County', label:'GRAY COUNTY', geo:'GRAY'},
{type:'County', label:'GRAYSON COUNTY', geo:'GRAYSON'},
{type:'County', label:'GREGG COUNTY', geo:'GREGG'},
{type:'County', label:'GRIMES COUNTY', geo:'GRIMES'},
{type:'County', label:'GUADALUPE COUNTY', geo:'GUADALUPE'},
{type:'County', label:'HALE COUNTY', geo:'HALE'},
{type:'County', label:'HALL COUNTY', geo:'HALL'},
{type:'County', label:'HAMILTON COUNTY', geo:'HAMILTON'},
{type:'County', label:'HANSFORD COUNTY', geo:'HANSFORD'},
{type:'County', label:'HARDEMAN COUNTY', geo:'HARDEMAN'},
{type:'County', label:'HARDIN COUNTY', geo:'HARDIN'},
{type:'County', label:'HARRIS COUNTY', geo:'HARRIS'},
{type:'County', label:'HARRISON COUNTY', geo:'HARRISON'},
{type:'County', label:'HARTLEY COUNTY', geo:'HARTLEY'},
{type:'County', label:'HASKELL COUNTY', geo:'HASKELL'},
{type:'County', label:'HAYS COUNTY', geo:'HAYS'},
{type:'County', label:'HEMPHILL COUNTY', geo:'HEMPHILL'},
{type:'County', label:'HENDERSON COUNTY', geo:'HENDERSON'},
{type:'County', label:'HIDALGO COUNTY', geo:'HIDALGO'},
{type:'County', label:'HILL COUNTY', geo:'HILL'},
{type:'County', label:'HOCKLEY COUNTY', geo:'HOCKLEY'},
{type:'County', label:'HOOD COUNTY', geo:'HOOD'},
{type:'County', label:'HOPKINS COUNTY', geo:'HOPKINS'},
{type:'County', label:'HOUSTON COUNTY', geo:'HOUSTON'},
{type:'County', label:'HOWARD COUNTY', geo:'HOWARD'},
{type:'County', label:'HUDSPETH COUNTY', geo:'HUDSPETH'},
{type:'County', label:'HUNT COUNTY', geo:'HUNT'},
{type:'County', label:'HUTCHINSON COUNTY', geo:'HUTCHINSON'},
{type:'County', label:'IRION COUNTY', geo:'IRION'},
{type:'County', label:'JACK COUNTY', geo:'JACK'},
{type:'County', label:'JACKSON COUNTY', geo:'JACKSON'},
{type:'County', label:'JASPER COUNTY', geo:'JASPER'},
{type:'County', label:'JEFF DAVIS COUNTY', geo:'JEFF DAVIS'},
{type:'County', label:'JEFFERSON COUNTY', geo:'JEFFERSON'},
{type:'County', label:'JIM HOGG COUNTY', geo:'JIM HOGG'},
{type:'County', label:'JIM WELLS COUNTY', geo:'JIM WELLS'},
{type:'County', label:'JOHNSON COUNTY', geo:'JOHNSON'},
{type:'County', label:'JONES COUNTY', geo:'JONES'},
{type:'County', label:'KARNES COUNTY', geo:'KARNES'},
{type:'County', label:'KAUFMAN COUNTY', geo:'KAUFMAN'},
{type:'County', label:'KENDALL COUNTY', geo:'KENDALL'},
{type:'County', label:'KENEDY COUNTY', geo:'KENEDY'},
{type:'County', label:'KENT COUNTY', geo:'KENT'},
{type:'County', label:'KERR COUNTY', geo:'KERR'},
{type:'County', label:'KIMBLE COUNTY', geo:'KIMBLE'},
{type:'County', label:'KING COUNTY', geo:'KING'},
{type:'County', label:'KINNEY COUNTY', geo:'KINNEY'},
{type:'County', label:'KLEBERG COUNTY', geo:'KLEBERG'},
{type:'County', label:'KNOX COUNTY', geo:'KNOX'},
{type:'County', label:'LAMAR COUNTY', geo:'LAMAR'},
{type:'County', label:'LAMB COUNTY', geo:'LAMB'},
{type:'County', label:'LAMPASAS COUNTY', geo:'LAMPASAS'},
{type:'County', label:'LA SALLE COUNTY', geo:'LA SALLE'},
{type:'County', label:'LAVACA COUNTY', geo:'LAVACA'},
{type:'County', label:'LEE COUNTY', geo:'LEE'},
{type:'County', label:'LEON COUNTY', geo:'LEON'},
{type:'County', label:'LIBERTY COUNTY', geo:'LIBERTY'},
{type:'County', label:'LIMESTONE COUNTY', geo:'LIMESTONE'},
{type:'County', label:'LIPSCOMB COUNTY', geo:'LIPSCOMB'},
{type:'County', label:'LIVE OAK COUNTY', geo:'LIVE OAK'},
{type:'County', label:'LLANO COUNTY', geo:'LLANO'},
{type:'County', label:'LOVING COUNTY', geo:'LOVING'},
{type:'County', label:'LUBBOCK COUNTY', geo:'LUBBOCK'},
{type:'County', label:'LYNN COUNTY', geo:'LYNN'},
{type:'County', label:'MADISON COUNTY', geo:'MADISON'},
{type:'County', label:'MARION COUNTY', geo:'MARION'},
{type:'County', label:'MARTIN COUNTY', geo:'MARTIN'},
{type:'County', label:'MASON COUNTY', geo:'MASON'},
{type:'County', label:'MATAGORDA COUNTY', geo:'MATAGORDA'},
{type:'County', label:'MAVERICK COUNTY', geo:'MAVERICK'},
{type:'County', label:'MCCULLOCH COUNTY', geo:'MCCULLOCH'},
{type:'County', label:'MCLENNAN COUNTY', geo:'MCLENNAN'},
{type:'County', label:'MCMULLEN COUNTY', geo:'MCMULLEN'},
{type:'County', label:'MEDINA COUNTY', geo:'MEDINA'},
{type:'County', label:'MENARD COUNTY', geo:'MENARD'},
{type:'County', label:'MIDLAND COUNTY', geo:'MIDLAND'},
{type:'County', label:'MILAM COUNTY', geo:'MILAM'},
{type:'County', label:'MILLS COUNTY', geo:'MILLS'},
{type:'County', label:'MITCHELL COUNTY', geo:'MITCHELL'},
{type:'County', label:'MONTAGUE COUNTY', geo:'MONTAGUE'},
{type:'County', label:'MONTGOMERY COUNTY', geo:'MONTGOMERY'},
{type:'County', label:'MOORE COUNTY', geo:'MOORE'},
{type:'County', label:'MORRIS COUNTY', geo:'MORRIS'},
{type:'County', label:'MOTLEY COUNTY', geo:'MOTLEY'},
{type:'County', label:'NACOGDOCHES COUNTY', geo:'NACOGDOCHES'},
{type:'County', label:'NAVARRO COUNTY', geo:'NAVARRO'},
{type:'County', label:'NEWTON COUNTY', geo:'NEWTON'},
{type:'County', label:'NOLAN COUNTY', geo:'NOLAN'},
{type:'County', label:'NUECES COUNTY', geo:'NUECES'},
{type:'County', label:'OCHILTREE COUNTY', geo:'OCHILTREE'},
{type:'County', label:'OLDHAM COUNTY', geo:'OLDHAM'},
{type:'County', label:'ORANGE COUNTY', geo:'ORANGE'},
{type:'County', label:'PALO PINTO COUNTY', geo:'PALO PINTO'},
{type:'County', label:'PANOLA COUNTY', geo:'PANOLA'},
{type:'County', label:'PARKER COUNTY', geo:'PARKER'},
{type:'County', label:'PARMER COUNTY', geo:'PARMER'},
{type:'County', label:'PECOS COUNTY', geo:'PECOS'},
{type:'County', label:'POLK COUNTY', geo:'POLK'},
{type:'County', label:'POTTER COUNTY', geo:'POTTER'},
{type:'County', label:'PRESIDIO COUNTY', geo:'PRESIDIO'},
{type:'County', label:'RAINS COUNTY', geo:'RAINS'},
{type:'County', label:'RANDALL COUNTY', geo:'RANDALL'},
{type:'County', label:'REAGAN COUNTY', geo:'REAGAN'},
{type:'County', label:'REAL COUNTY', geo:'REAL'},
{type:'County', label:'RED RIVER COUNTY', geo:'RED RIVER'},
{type:'County', label:'REEVES COUNTY', geo:'REEVES'},
{type:'County', label:'REFUGIO COUNTY', geo:'REFUGIO'},
{type:'County', label:'ROBERTS COUNTY', geo:'ROBERTS'},
{type:'County', label:'ROBERTSON COUNTY', geo:'ROBERTSON'},
{type:'County', label:'ROCKWALL COUNTY', geo:'ROCKWALL'},
{type:'County', label:'RUNNELS COUNTY', geo:'RUNNELS'},
{type:'County', label:'RUSK COUNTY', geo:'RUSK'},
{type:'County', label:'SABINE COUNTY', geo:'SABINE'},
{type:'County', label:'SAN AUGUSTINE COUNTY', geo:'SAN AUGUSTINE'},
{type:'County', label:'SAN JACINTO COUNTY', geo:'SAN JACINTO'},
{type:'County', label:'SAN PATRICIO COUNTY', geo:'SAN PATRICIO'},
{type:'County', label:'SAN SABA COUNTY', geo:'SAN SABA'},
{type:'County', label:'SCHLEICHER COUNTY', geo:'SCHLEICHER'},
{type:'County', label:'SCURRY COUNTY', geo:'SCURRY'},
{type:'County', label:'SHACKELFORD COUNTY', geo:'SHACKELFORD'},
{type:'County', label:'SHELBY COUNTY', geo:'SHELBY'},
{type:'County', label:'SHERMAN COUNTY', geo:'SHERMAN'},
{type:'County', label:'SMITH COUNTY', geo:'SMITH'},
{type:'County', label:'SOMERVELL COUNTY', geo:'SOMERVELL'},
{type:'County', label:'STARR COUNTY', geo:'STARR'},
{type:'County', label:'STEPHENS COUNTY', geo:'STEPHENS'},
{type:'County', label:'STERLING COUNTY', geo:'STERLING'},
{type:'County', label:'STONEWALL COUNTY', geo:'STONEWALL'},
{type:'County', label:'SUTTON COUNTY', geo:'SUTTON'},
{type:'County', label:'SWISHER COUNTY', geo:'SWISHER'},
{type:'County', label:'TARRANT COUNTY', geo:'TARRANT'},
{type:'County', label:'TAYLOR COUNTY', geo:'TAYLOR'},
{type:'County', label:'TERRELL COUNTY', geo:'TERRELL'},
{type:'County', label:'TERRY COUNTY', geo:'TERRY'},
{type:'County', label:'THROCKMORTON COUNTY', geo:'THROCKMORTON'},
{type:'County', label:'TITUS COUNTY', geo:'TITUS'},
{type:'County', label:'TOM GREEN COUNTY', geo:'TOM GREEN'},
{type:'County', label:'TRAVIS COUNTY', geo:'TRAVIS'},
{type:'County', label:'TRINITY COUNTY', geo:'TRINITY'},
{type:'County', label:'TYLER COUNTY', geo:'TYLER'},
{type:'County', label:'UPSHUR COUNTY', geo:'UPSHUR'},
{type:'County', label:'UPTON COUNTY', geo:'UPTON'},
{type:'County', label:'UVALDE COUNTY', geo:'UVALDE'},
{type:'County', label:'VAL VERDE COUNTY', geo:'VAL VERDE'},
{type:'County', label:'VAN ZANDT COUNTY', geo:'VAN ZANDT'},
{type:'County', label:'VICTORIA COUNTY', geo:'VICTORIA'},
{type:'County', label:'WALKER COUNTY', geo:'WALKER'},
{type:'County', label:'WALLER COUNTY', geo:'WALLER'},
{type:'County', label:'WARD COUNTY', geo:'WARD'},
{type:'County', label:'WASHINGTON COUNTY', geo:'WASHINGTON'},
{type:'County', label:'WEBB COUNTY', geo:'WEBB'},
{type:'County', label:'WHARTON COUNTY', geo:'WHARTON'},
{type:'County', label:'WHEELER COUNTY', geo:'WHEELER'},
{type:'County', label:'WICHITA COUNTY', geo:'WICHITA'},
{type:'County', label:'WILBARGER COUNTY', geo:'WILBARGER'},
{type:'County', label:'WILLACY COUNTY', geo:'WILLACY'},
{type:'County', label:'WILLIAMSON COUNTY', geo:'WILLIAMSON'},
{type:'County', label:'WILSON COUNTY', geo:'WILSON'},
{type:'County', label:'WINKLER COUNTY', geo:'WINKLER'},
{type:'County', label:'WISE COUNTY', geo:'WISE'},
{type:'County', label:'WOOD COUNTY', geo:'WOOD'},
{type:'County', label:'YOAKUM COUNTY', geo:'YOAKUM'},
{type:'County', label:'YOUNG COUNTY', geo:'YOUNG'},
{type:'County', label:'ZAPATA COUNTY', geo:'ZAPATA'},
{type:'County', label:'ZAVALA COUNTY', geo:'ZAVALA'}
];

geolist['ok'] = [
{type:'CD', label:'CD1', geo:'1'},
{type:'CD', label:'CD2', geo:'2'},
{type:'CD', label:'CD3', geo:'3'},
{type:'CD', label:'CD4', geo:'4'},
{type:'CD', label:'CD5', geo:'5'},
{type:'SD', label:'SD1', geo:'1'},
{type:'SD', label:'SD2', geo:'2'},
{type:'SD', label:'SD3', geo:'3'},
{type:'SD', label:'SD4', geo:'4'},
{type:'SD', label:'SD5', geo:'5'},
{type:'SD', label:'SD6', geo:'6'},
{type:'SD', label:'SD7', geo:'7'},
{type:'SD', label:'SD8', geo:'8'},
{type:'SD', label:'SD9', geo:'9'},
{type:'SD', label:'SD10', geo:'10'},
{type:'SD', label:'SD11', geo:'11'},
{type:'SD', label:'SD12', geo:'12'},
{type:'SD', label:'SD13', geo:'13'},
{type:'SD', label:'SD14', geo:'14'},
{type:'SD', label:'SD15', geo:'15'},
{type:'SD', label:'SD16', geo:'16'},
{type:'SD', label:'SD17', geo:'17'},
{type:'SD', label:'SD18', geo:'18'},
{type:'SD', label:'SD19', geo:'19'},
{type:'SD', label:'SD20', geo:'20'},
{type:'SD', label:'SD21', geo:'21'},
{type:'SD', label:'SD22', geo:'22'},
{type:'SD', label:'SD23', geo:'23'},
{type:'SD', label:'SD24', geo:'24'},
{type:'SD', label:'SD25', geo:'25'},
{type:'SD', label:'SD26', geo:'26'},
{type:'SD', label:'SD27', geo:'27'},
{type:'SD', label:'SD28', geo:'28'},
{type:'SD', label:'SD29', geo:'29'},
{type:'SD', label:'SD30', geo:'30'},
{type:'SD', label:'SD31', geo:'31'},
{type:'SD', label:'SD32', geo:'32'},
{type:'SD', label:'SD33', geo:'33'},
{type:'SD', label:'SD34', geo:'34'},
{type:'SD', label:'SD35', geo:'35'},
{type:'SD', label:'SD36', geo:'36'},
{type:'SD', label:'SD37', geo:'37'},
{type:'SD', label:'SD38', geo:'38'},
{type:'SD', label:'SD39', geo:'39'},
{type:'SD', label:'SD40', geo:'40'},
{type:'SD', label:'SD41', geo:'41'},
{type:'SD', label:'SD42', geo:'42'},
{type:'SD', label:'SD43', geo:'43'},
{type:'SD', label:'SD44', geo:'44'},
{type:'SD', label:'SD45', geo:'45'},
{type:'SD', label:'SD46', geo:'46'},
{type:'SD', label:'SD47', geo:'47'},
{type:'SD', label:'SD48', geo:'48'},
{type:'HD', label:'HD1', geo:'1'},
{type:'HD', label:'HD2', geo:'2'},
{type:'HD', label:'HD3', geo:'3'},
{type:'HD', label:'HD4', geo:'4'},
{type:'HD', label:'HD5', geo:'5'},
{type:'HD', label:'HD6', geo:'6'},
{type:'HD', label:'HD7', geo:'7'},
{type:'HD', label:'HD8', geo:'8'},
{type:'HD', label:'HD9', geo:'9'},
{type:'HD', label:'HD10', geo:'10'},
{type:'HD', label:'HD11', geo:'11'},
{type:'HD', label:'HD12', geo:'12'},
{type:'HD', label:'HD13', geo:'13'},
{type:'HD', label:'HD14', geo:'14'},
{type:'HD', label:'HD15', geo:'15'},
{type:'HD', label:'HD16', geo:'16'},
{type:'HD', label:'HD17', geo:'17'},
{type:'HD', label:'HD18', geo:'18'},
{type:'HD', label:'HD19', geo:'19'},
{type:'HD', label:'HD20', geo:'20'},
{type:'HD', label:'HD21', geo:'21'},
{type:'HD', label:'HD22', geo:'22'},
{type:'HD', label:'HD23', geo:'23'},
{type:'HD', label:'HD24', geo:'24'},
{type:'HD', label:'HD25', geo:'25'},
{type:'HD', label:'HD26', geo:'26'},
{type:'HD', label:'HD27', geo:'27'},
{type:'HD', label:'HD28', geo:'28'},
{type:'HD', label:'HD29', geo:'29'},
{type:'HD', label:'HD30', geo:'30'},
{type:'HD', label:'HD31', geo:'31'},
{type:'HD', label:'HD32', geo:'32'},
{type:'HD', label:'HD33', geo:'33'},
{type:'HD', label:'HD34', geo:'34'},
{type:'HD', label:'HD35', geo:'35'},
{type:'HD', label:'HD36', geo:'36'},
{type:'HD', label:'HD37', geo:'37'},
{type:'HD', label:'HD38', geo:'38'},
{type:'HD', label:'HD39', geo:'39'},
{type:'HD', label:'HD40', geo:'40'},
{type:'HD', label:'HD41', geo:'41'},
{type:'HD', label:'HD42', geo:'42'},
{type:'HD', label:'HD43', geo:'43'},
{type:'HD', label:'HD44', geo:'44'},
{type:'HD', label:'HD45', geo:'45'},
{type:'HD', label:'HD46', geo:'46'},
{type:'HD', label:'HD47', geo:'47'},
{type:'HD', label:'HD48', geo:'48'},
{type:'HD', label:'HD49', geo:'49'},
{type:'HD', label:'HD50', geo:'50'},
{type:'HD', label:'HD51', geo:'51'},
{type:'HD', label:'HD52', geo:'52'},
{type:'HD', label:'HD53', geo:'53'},
{type:'HD', label:'HD54', geo:'54'},
{type:'HD', label:'HD55', geo:'55'},
{type:'HD', label:'HD56', geo:'56'},
{type:'HD', label:'HD57', geo:'57'},
{type:'HD', label:'HD58', geo:'58'},
{type:'HD', label:'HD59', geo:'59'},
{type:'HD', label:'HD60', geo:'60'},
{type:'HD', label:'HD61', geo:'61'},
{type:'HD', label:'HD62', geo:'62'},
{type:'HD', label:'HD63', geo:'63'},
{type:'HD', label:'HD64', geo:'64'},
{type:'HD', label:'HD65', geo:'65'},
{type:'HD', label:'HD66', geo:'66'},
{type:'HD', label:'HD67', geo:'67'},
{type:'HD', label:'HD68', geo:'68'},
{type:'HD', label:'HD69', geo:'69'},
{type:'HD', label:'HD70', geo:'70'},
{type:'HD', label:'HD71', geo:'71'},
{type:'HD', label:'HD72', geo:'72'},
{type:'HD', label:'HD73', geo:'73'},
{type:'HD', label:'HD74', geo:'74'},
{type:'HD', label:'HD75', geo:'75'},
{type:'HD', label:'HD76', geo:'76'},
{type:'HD', label:'HD77', geo:'77'},
{type:'HD', label:'HD78', geo:'78'},
{type:'HD', label:'HD79', geo:'79'},
{type:'HD', label:'HD80', geo:'80'},
{type:'HD', label:'HD81', geo:'81'},
{type:'HD', label:'HD82', geo:'82'},
{type:'HD', label:'HD83', geo:'83'},
{type:'HD', label:'HD84', geo:'84'},
{type:'HD', label:'HD85', geo:'85'},
{type:'HD', label:'HD86', geo:'86'},
{type:'HD', label:'HD87', geo:'87'},
{type:'HD', label:'HD88', geo:'88'},
{type:'HD', label:'HD89', geo:'89'},
{type:'HD', label:'HD90', geo:'90'},
{type:'HD', label:'HD91', geo:'91'},
{type:'HD', label:'HD92', geo:'92'},
{type:'HD', label:'HD93', geo:'93'},
{type:'HD', label:'HD94', geo:'94'},
{type:'HD', label:'HD95', geo:'95'},
{type:'HD', label:'HD96', geo:'96'},
{type:'HD', label:'HD97', geo:'97'},
{type:'HD', label:'HD98', geo:'98'},
{type:'HD', label:'HD99', geo:'99'},
{type:'HD', label:'HD100', geo:'100'},
{type:'HD', label:'HD101', geo:'101'},
{type:'County', label:'ADAIR COUNTY', geo:'ADAIR'},
{type:'County', label:'ALFALFA COUNTY', geo:'ALFALFA'},
{type:'County', label:'ATOKA COUNTY', geo:'ATOKA'},
{type:'County', label:'BEAVER COUNTY', geo:'BEAVER'},
{type:'County', label:'BECKHAM COUNTY', geo:'BECKHAM'},
{type:'County', label:'BLAINE COUNTY', geo:'BLAINE'},
{type:'County', label:'BRYAN COUNTY', geo:'BRYAN'},
{type:'County', label:'CADDO COUNTY', geo:'CADDO'},
{type:'County', label:'CANADIAN COUNTY', geo:'CANADIAN'},
{type:'County', label:'CARTER COUNTY', geo:'CARTER'},
{type:'County', label:'CHEROKEE COUNTY', geo:'CHEROKEE'},
{type:'County', label:'CHOCTAW COUNTY', geo:'CHOCTAW'},
{type:'County', label:'CIMARRON COUNTY', geo:'CIMARRON'},
{type:'County', label:'CLEVELAND COUNTY', geo:'CLEVELAND'},
{type:'County', label:'COAL COUNTY', geo:'COAL'},
{type:'County', label:'COMANCHE COUNTY', geo:'COMANCHE'},
{type:'County', label:'COTTON COUNTY', geo:'COTTON'},
{type:'County', label:'CRAIG COUNTY', geo:'CRAIG'},
{type:'County', label:'CREEK COUNTY', geo:'CREEK'},
{type:'County', label:'CUSTER COUNTY', geo:'CUSTER'},
{type:'County', label:'DELAWARE COUNTY', geo:'DELAWARE'},
{type:'County', label:'DEWEY COUNTY', geo:'DEWEY'},
{type:'County', label:'ELLIS COUNTY', geo:'ELLIS'},
{type:'County', label:'GARFIELD COUNTY', geo:'GARFIELD'},
{type:'County', label:'GARVIN COUNTY', geo:'GARVIN'},
{type:'County', label:'GRADY COUNTY', geo:'GRADY'},
{type:'County', label:'GRANT COUNTY', geo:'GRANT'},
{type:'County', label:'GREER COUNTY', geo:'GREER'},
{type:'County', label:'HARMON COUNTY', geo:'HARMON'},
{type:'County', label:'HARPER COUNTY', geo:'HARPER'},
{type:'County', label:'HASKELL COUNTY', geo:'HASKELL'},
{type:'County', label:'HUGHES COUNTY', geo:'HUGHES'},
{type:'County', label:'JACKSON COUNTY', geo:'JACKSON'},
{type:'County', label:'JEFFERSON COUNTY', geo:'JEFFERSON'},
{type:'County', label:'JOHNSTON COUNTY', geo:'JOHNSTON'},
{type:'County', label:'KAY COUNTY', geo:'KAY'},
{type:'County', label:'KINGFISHER COUNTY', geo:'KINGFISHER'},
{type:'County', label:'KIOWA COUNTY', geo:'KIOWA'},
{type:'County', label:'LATIMER COUNTY', geo:'LATIMER'},
{type:'County', label:'LE FLORE COUNTY', geo:'LE FLORE'},
{type:'County', label:'LINCOLN COUNTY', geo:'LINCOLN'},
{type:'County', label:'LOGAN COUNTY', geo:'LOGAN'},
{type:'County', label:'LOVE COUNTY', geo:'LOVE'},
{type:'County', label:'MAJOR COUNTY', geo:'MAJOR'},
{type:'County', label:'MARSHALL COUNTY', geo:'MARSHALL'},
{type:'County', label:'MAYES COUNTY', geo:'MAYES'},
{type:'County', label:'MCCLAIN COUNTY', geo:'MCCLAIN'},
{type:'County', label:'MCCURTAIN COUNTY', geo:'MCCURTAIN'},
{type:'County', label:'MCINTOSH COUNTY', geo:'MCINTOSH'},
{type:'County', label:'MURRAY COUNTY', geo:'MURRAY'},
{type:'County', label:'MUSKOGEE COUNTY', geo:'MUSKOGEE'},
{type:'County', label:'NOBLE COUNTY', geo:'NOBLE'},
{type:'County', label:'NOWATA COUNTY', geo:'NOWATA'},
{type:'County', label:'OKFUSKEE COUNTY', geo:'OKFUSKEE'},
{type:'County', label:'OKLAHOMA COUNTY', geo:'OKLAHOMA'},
{type:'County', label:'OKMULGEE COUNTY', geo:'OKMULGEE'},
{type:'County', label:'OSAGE COUNTY', geo:'OSAGE'},
{type:'County', label:'OTTAWA COUNTY', geo:'OTTAWA'},
{type:'County', label:'PAWNEE COUNTY', geo:'PAWNEE'},
{type:'County', label:'PAYNE COUNTY', geo:'PAYNE'},
{type:'County', label:'PITTSBURG COUNTY', geo:'PITTSBURG'},
{type:'County', label:'PONTOTOC COUNTY', geo:'PONTOTOC'},
{type:'County', label:'POTTAWATOMIE COUNTY', geo:'POTTAWATOMIE'},
{type:'County', label:'PUSHMATAHA COUNTY', geo:'PUSHMATAHA'},
{type:'County', label:'ROGER MILLS COUNTY', geo:'ROGER MILLS'},
{type:'County', label:'ROGERS COUNTY', geo:'ROGERS'},
{type:'County', label:'SEMINOLE COUNTY', geo:'SEMINOLE'},
{type:'County', label:'SEQUOYAH COUNTY', geo:'SEQUOYAH'},
{type:'County', label:'STEPHENS COUNTY', geo:'STEPHENS'},
{type:'County', label:'TEXAS COUNTY', geo:'TEXAS'},
{type:'County', label:'TILLMAN COUNTY', geo:'TILLMAN'},
{type:'County', label:'TULSA COUNTY', geo:'TULSA'},
{type:'County', label:'WAGONER COUNTY', geo:'WAGONER'},
{type:'County', label:'WASHINGTON COUNTY', geo:'WASHINGTON'},
{type:'County', label:'WASHITA COUNTY', geo:'WASHITA'},
{type:'County', label:'WOODS COUNTY', geo:'WOODS'},
{type:'County', label:'WOODWARD COUNTY', geo:'WOODWARD'}
];

geolist['ar'] = [
{type:'CD', label:'CD1', geo:'1'},
{type:'CD', label:'CD2', geo:'2'},
{type:'CD', label:'CD3', geo:'3'},
{type:'CD', label:'CD4', geo:'4'},
{type:'SD', label:'SD1', geo:'1'},
{type:'SD', label:'SD2', geo:'2'},
{type:'SD', label:'SD3', geo:'3'},
{type:'SD', label:'SD4', geo:'4'},
{type:'SD', label:'SD5', geo:'5'},
{type:'SD', label:'SD6', geo:'6'},
{type:'SD', label:'SD7', geo:'7'},
{type:'SD', label:'SD8', geo:'8'},
{type:'SD', label:'SD9', geo:'9'},
{type:'SD', label:'SD10', geo:'10'},
{type:'SD', label:'SD11', geo:'11'},
{type:'SD', label:'SD12', geo:'12'},
{type:'SD', label:'SD13', geo:'13'},
{type:'SD', label:'SD14', geo:'14'},
{type:'SD', label:'SD15', geo:'15'},
{type:'SD', label:'SD16', geo:'16'},
{type:'SD', label:'SD17', geo:'17'},
{type:'SD', label:'SD18', geo:'18'},
{type:'SD', label:'SD19', geo:'19'},
{type:'SD', label:'SD20', geo:'20'},
{type:'SD', label:'SD21', geo:'21'},
{type:'SD', label:'SD22', geo:'22'},
{type:'SD', label:'SD23', geo:'23'},
{type:'SD', label:'SD24', geo:'24'},
{type:'SD', label:'SD25', geo:'25'},
{type:'SD', label:'SD26', geo:'26'},
{type:'SD', label:'SD27', geo:'27'},
{type:'SD', label:'SD28', geo:'28'},
{type:'SD', label:'SD29', geo:'29'},
{type:'SD', label:'SD30', geo:'30'},
{type:'SD', label:'SD41', geo:'31'},
{type:'SD', label:'SD42', geo:'32'},
{type:'SD', label:'SD43', geo:'33'},
{type:'SD', label:'SD44', geo:'34'},
{type:'SD', label:'SD45', geo:'35'},
{type:'HD', label:'HD1', geo:'1'},
{type:'HD', label:'HD2', geo:'2'},
{type:'HD', label:'HD3', geo:'3'},
{type:'HD', label:'HD4', geo:'4'},
{type:'HD', label:'HD5', geo:'5'},
{type:'HD', label:'HD6', geo:'6'},
{type:'HD', label:'HD7', geo:'7'},
{type:'HD', label:'HD8', geo:'8'},
{type:'HD', label:'HD9', geo:'9'},
{type:'HD', label:'HD10', geo:'10'},
{type:'HD', label:'HD11', geo:'11'},
{type:'HD', label:'HD12', geo:'12'},
{type:'HD', label:'HD13', geo:'13'},
{type:'HD', label:'HD14', geo:'14'},
{type:'HD', label:'HD15', geo:'15'},
{type:'HD', label:'HD16', geo:'16'},
{type:'HD', label:'HD17', geo:'17'},
{type:'HD', label:'HD18', geo:'18'},
{type:'HD', label:'HD19', geo:'19'},
{type:'HD', label:'HD20', geo:'20'},
{type:'HD', label:'HD21', geo:'21'},
{type:'HD', label:'HD22', geo:'22'},
{type:'HD', label:'HD23', geo:'23'},
{type:'HD', label:'HD24', geo:'24'},
{type:'HD', label:'HD25', geo:'25'},
{type:'HD', label:'HD26', geo:'26'},
{type:'HD', label:'HD27', geo:'27'},
{type:'HD', label:'HD28', geo:'28'},
{type:'HD', label:'HD29', geo:'29'},
{type:'HD', label:'HD30', geo:'30'},
{type:'HD', label:'HD31', geo:'31'},
{type:'HD', label:'HD32', geo:'32'},
{type:'HD', label:'HD33', geo:'33'},
{type:'HD', label:'HD34', geo:'34'},
{type:'HD', label:'HD35', geo:'35'},
{type:'HD', label:'HD36', geo:'36'},
{type:'HD', label:'HD37', geo:'37'},
{type:'HD', label:'HD38', geo:'38'},
{type:'HD', label:'HD39', geo:'39'},
{type:'HD', label:'HD40', geo:'40'},
{type:'HD', label:'HD41', geo:'41'},
{type:'HD', label:'HD42', geo:'42'},
{type:'HD', label:'HD43', geo:'43'},
{type:'HD', label:'HD44', geo:'44'},
{type:'HD', label:'HD45', geo:'45'},
{type:'HD', label:'HD46', geo:'46'},
{type:'HD', label:'HD47', geo:'47'},
{type:'HD', label:'HD48', geo:'48'},
{type:'HD', label:'HD49', geo:'49'},
{type:'HD', label:'HD50', geo:'50'},
{type:'HD', label:'HD51', geo:'51'},
{type:'HD', label:'HD52', geo:'52'},
{type:'HD', label:'HD53', geo:'53'},
{type:'HD', label:'HD54', geo:'54'},
{type:'HD', label:'HD55', geo:'55'},
{type:'HD', label:'HD56', geo:'56'},
{type:'HD', label:'HD57', geo:'57'},
{type:'HD', label:'HD58', geo:'58'},
{type:'HD', label:'HD59', geo:'59'},
{type:'HD', label:'HD60', geo:'60'},
{type:'HD', label:'HD61', geo:'61'},
{type:'HD', label:'HD62', geo:'62'},
{type:'HD', label:'HD63', geo:'63'},
{type:'HD', label:'HD64', geo:'64'},
{type:'HD', label:'HD65', geo:'65'},
{type:'HD', label:'HD66', geo:'66'},
{type:'HD', label:'HD67', geo:'67'},
{type:'HD', label:'HD68', geo:'68'},
{type:'HD', label:'HD69', geo:'69'},
{type:'HD', label:'HD70', geo:'70'},
{type:'HD', label:'HD71', geo:'71'},
{type:'HD', label:'HD72', geo:'72'},
{type:'HD', label:'HD73', geo:'73'},
{type:'HD', label:'HD74', geo:'74'},
{type:'HD', label:'HD75', geo:'75'},
{type:'HD', label:'HD76', geo:'76'},
{type:'HD', label:'HD77', geo:'77'},
{type:'HD', label:'HD78', geo:'78'},
{type:'HD', label:'HD79', geo:'79'},
{type:'HD', label:'HD80', geo:'80'},
{type:'HD', label:'HD81', geo:'81'},
{type:'HD', label:'HD82', geo:'82'},
{type:'HD', label:'HD83', geo:'83'},
{type:'HD', label:'HD84', geo:'84'},
{type:'HD', label:'HD85', geo:'85'},
{type:'HD', label:'HD86', geo:'86'},
{type:'HD', label:'HD87', geo:'87'},
{type:'HD', label:'HD88', geo:'88'},
{type:'HD', label:'HD89', geo:'89'},
{type:'HD', label:'HD90', geo:'90'},
{type:'HD', label:'HD91', geo:'91'},
{type:'HD', label:'HD92', geo:'92'},
{type:'HD', label:'HD93', geo:'93'},
{type:'HD', label:'HD94', geo:'94'},
{type:'HD', label:'HD95', geo:'95'},
{type:'HD', label:'HD96', geo:'96'},
{type:'HD', label:'HD97', geo:'97'},
{type:'HD', label:'HD98', geo:'98'},
{type:'HD', label:'HD99', geo:'99'},
{type:'HD', label:'HD100', geo:'100'},
{type:'County', label:'ARKANSAS COUNTY', geo:'ARKANSAS'},
{type:'County', label:'ASHLEY COUNTY', geo:'ASHLEY'},
{type:'County', label:'BAXTER COUNTY', geo:'BAXTER'},
{type:'County', label:'BENTON COUNTY', geo:'BENTON'},
{type:'County', label:'BOONE COUNTY', geo:'BOONE'},
{type:'County', label:'BRADLEY COUNTY', geo:'BRADLEY'},
{type:'County', label:'CALHOUN COUNTY', geo:'CALHOUN'},
{type:'County', label:'CARROLL COUNTY', geo:'CARROLL'},
{type:'County', label:'CHICOT COUNTY', geo:'CHICOT'},
{type:'County', label:'CLARK COUNTY', geo:'CLARK'},
{type:'County', label:'CLAY COUNTY', geo:'CLAY'},
{type:'County', label:'CLEBURNE COUNTY', geo:'CLEBURNE'},
{type:'County', label:'CLEVELAND COUNTY', geo:'CLEVELAND'},
{type:'County', label:'COLUMBIA COUNTY', geo:'COLUMBIA'},
{type:'County', label:'CONWAY COUNTY', geo:'CONWAY'},
{type:'County', label:'CRAIGHEAD COUNTY', geo:'CRAIGHEAD'},
{type:'County', label:'CRAWFORD COUNTY', geo:'CRAWFORD'},
{type:'County', label:'CRITTENDEN COUNTY', geo:'CRITTENDEN'},
{type:'County', label:'CROSS COUNTY', geo:'CROSS'},
{type:'County', label:'DALLAS COUNTY', geo:'DALLAS'},
{type:'County', label:'DESHA COUNTY', geo:'DESHA'},
{type:'County', label:'DREW COUNTY', geo:'DREW'},
{type:'County', label:'FAULKNER COUNTY', geo:'FAULKNER'},
{type:'County', label:'FRANKLIN COUNTY', geo:'FRANKLIN'},
{type:'County', label:'FULTON COUNTY', geo:'FULTON'},
{type:'County', label:'GARLAND COUNTY', geo:'GARLAND'},
{type:'County', label:'GRANT COUNTY', geo:'GRANT'},
{type:'County', label:'GREENE COUNTY', geo:'GREENE'},
{type:'County', label:'HEMPSTEAD COUNTY', geo:'HEMPSTEAD'},
{type:'County', label:'HOT SPRING COUNTY', geo:'HOT SPRING'},
{type:'County', label:'HOWARD COUNTY', geo:'HOWARD'},
{type:'County', label:'INDEPENDENCE COUNTY', geo:'INDEPENDENCE'},
{type:'County', label:'IZARD COUNTY', geo:'IZARD'},
{type:'County', label:'JACKSON COUNTY', geo:'JACKSON'},
{type:'County', label:'JEFFERSON COUNTY', geo:'JEFFERSON'},
{type:'County', label:'JOHNSON COUNTY', geo:'JOHNSON'},
{type:'County', label:'LAFAYETTE COUNTY', geo:'LAFAYETTE'},
{type:'County', label:'LAWRENCE COUNTY', geo:'LAWRENCE'},
{type:'County', label:'LEE COUNTY', geo:'LEE'},
{type:'County', label:'LINCOLN COUNTY', geo:'LINCOLN'},
{type:'County', label:'LITTLE RIVER COUNTY', geo:'LITTLE RIVER'},
{type:'County', label:'LOGAN COUNTY', geo:'LOGAN'},
{type:'County', label:'LONOKE COUNTY', geo:'LONOKE'},
{type:'County', label:'MADISON COUNTY', geo:'MADISON'},
{type:'County', label:'MARION COUNTY', geo:'MARION'},
{type:'County', label:'MILLER COUNTY', geo:'MILLER'},
{type:'County', label:'MISSISSIPPI COUNTY', geo:'MISSISSIPPI'},
{type:'County', label:'MONROE COUNTY', geo:'MONROE'},
{type:'County', label:'MONTGOMERY COUNTY', geo:'MONTGOMERY'},
{type:'County', label:'NEVADA COUNTY', geo:'NEVADA'},
{type:'County', label:'NEWTON COUNTY', geo:'NEWTON'},
{type:'County', label:'OUACHITA COUNTY', geo:'OUACHITA'},
{type:'County', label:'PERRY COUNTY', geo:'PERRY'},
{type:'County', label:'PHILLIPS COUNTY', geo:'PHILLIPS'},
{type:'County', label:'PIKE COUNTY', geo:'PIKE'},
{type:'County', label:'POINSETT COUNTY', geo:'POINSETT'},
{type:'County', label:'POLK COUNTY', geo:'POLK'},
{type:'County', label:'POPE COUNTY', geo:'POPE'},
{type:'County', label:'PRAIRIE COUNTY', geo:'PRAIRIE'},
{type:'County', label:'PULASKI COUNTY', geo:'PULASKI'},
{type:'County', label:'RANDOLPH COUNTY', geo:'RANDOLPH'},
{type:'County', label:'SALINE COUNTY', geo:'SALINE'},
{type:'County', label:'SCOTT COUNTY', geo:'SCOTT'},
{type:'County', label:'SEARCY COUNTY', geo:'SEARCY'},
{type:'County', label:'SEBASTIAN COUNTY', geo:'SEBASTIAN'},
{type:'County', label:'SEVIER COUNTY', geo:'SEVIER'},
{type:'County', label:'SHARP COUNTY', geo:'SHARP'},
{type:'County', label:'ST. FRANCIS COUNTY', geo:'ST. FRANCIS'},
{type:'County', label:'STONE COUNTY', geo:'STONE'},
{type:'County', label:'UNION COUNTY', geo:'UNION'},
{type:'County', label:'VAN BUREN COUNTY', geo:'VAN BUREN'},
{type:'County', label:'WASHINGTON COUNTY', geo:'WASHINGTON'},
{type:'County', label:'WHITE COUNTY', geo:'WHITE'},
{type:'County', label:'WOODRUFF COUNTY', geo:'WOODRUFF'},
{type:'County', label:'YELL COUNTY', geo:'YELL'}
];